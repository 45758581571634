.switcher {
  --w: 3.8rem;
  --h: 2.4rem;
  --p: 0.3rem;
  --thumb-size: calc(var(--h) - var(--p) * 2);
  width: var(--w);
  height: var(--h);
  appearance: none;
  border: none;
  box-shadow: none;
  border-radius: 999px;
  position: relative;
  cursor: pointer;
  background-color: #bebebe;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--gradient-primary);
    border-radius: inherit;
    opacity: 0;
    @apply transition-opacity;
  }
  &::after {
    content: '';
    position: absolute;
    top: var(--p);
    left: var(--p);
    height: var(--thumb-size);
    width: var(--thumb-size);
    background-color: #fff;
    border-radius: inherit;
    @apply transition-transform;
  }
  &:is(:checked, .checked) {
    &::before {
      opacity: 1;
    }
    &::after {
      transform: translateX(calc(var(--w) - var(--p) * 2 - var(--thumb-size)));
    }
  }
}
