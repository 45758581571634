:root {
  --modal-z: 99;
  --body-scrollbar-width: 0px;
}

/* .modal-prevent-scroll {
   overscroll-behavior: none;
   touch-action: none;
   overflow: hidden;
   padding-right: var(--body-scrollbar-width);
} */
.modal-backdrop {
  @apply absolute inset-0;
  transform: translateZ(0);
  z-index: calc(var(--modal-z) - 1);
  background-color: rgba(22, 23, 22, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden auto;
  @screen sm {
    //padding: 2rem;
  }
  &--dark {
    background-color: rgba(22, 23, 22, 0.45);
  }
}
.modal {
  @apply fixed inset-0;
  z-index: var(--modal-z);
  &:focus {
    outline: none;
  }
}
.modal-content {
  max-width: 100%;
  z-index: var(--modal-z);
  position: relative;

  @screen lg {
    margin: auto;
  }
  &--primary {
    @apply rounded-t lg:rounded;
    background-color: #fafcfa;
  }
}
