.btn-base {
  text-align: inherit;
  outline: none;
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border-width: 0;
  font-weight: inherit;
  color: inherit;
  display: inline-block;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter;
  transition-duration: 0.15s;
}

.btn-base svg {
  pointer-events: none;
}

.btn-base:focus {
  outline: none;
}

:is(.btn-base, a):focus {
  outline: none;
}

// :is(.btn-base, a):focus-visible {
//   @apply outline-none lg:outline lg:outline-4 lg:outline-offset-2 lg:outline-primary;
// }

.btn {
  --border-radius: theme('borderRadius.DEFAULT');
  --disabled-opacity: 0.5;
  --loader-size: 1.2em;
  --loader-color: currentColor;
  --loader-border-width: 30%;
  --loader-progress: 50%;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter;
  transition-duration: 0.15s;

  appearance: none;
  background-color: var(--bg, transparent);
  border-width: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: none;
  line-height: 1;
  z-index: 1;
  vertical-align: middle;
  border-radius: var(--border-radius, 0);
  font-weight: inherit;
  font-size: var(--font-size, inherit);
  padding-left: var(--padding, 0);
  padding-right: var(--padding, 0);
  height: var(--size, auto);
  min-width: var(--size, auto);
  color: var(--color, inherit);
  outline: none;
  border-style: solid;
  border-color: var(--border-color, var(--bg));
  flex-shrink: 0;

  &:focus {
    outline: none;
  }

  // This style only applies when the data-focus-visible-added attribute is present
  &[data-focus-visible-added] {
    @apply lg:outline lg:outline-4 lg:outline-offset-2 lg:outline-primary;
  }

  // This removes the outline when the element is focused BUT doesn't have data-focus-visible-added
  &:focus:not([data-focus-visible-added]) {
    outline: none;
  }

  &:is([disabled], :disabled, .disabled) {
    opacity: var(--disabled-opacity);
    cursor: not-allowed;
  }

  &--disabled-color:is([disabled], :disabled, .disabled) {
    opacity: 1;
    background: #f2f5f2;
    @apply text-gray-dark;
  }

  svg {
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: inherit;
    opacity: 0;
    transition: inherit;
  }
}

.btn--rounded {
  border-radius: calc(var(--size, 999px) / 2);
}

.btn--icon {
  width: var(--size);
  padding: 0;
}

.btn--padding {
  width: auto;
  padding-left: var(--padding, 0);
  padding-right: var(--padding, 0);
}

.btn--text {
  color: var(--bg, inherit);
  background-color: transparent;
}

.btn.loading:not(.btn--no-loader) {
  -webkit-text-fill-color: transparent;

  & > * {
    visibility: hidden;
  }

  &::after {
    content: '';
    @apply loader;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    animation: loading-offset var(--loader-speed, 0.6s) linear infinite;
  }

  .hide-on-loading {
    display: none;
  }
}

/* --------------------------------------------------------------- */

.btn {
  border-radius: calc(var(--size, 999px) / 2);
  font-weight: bold;
  gap: 1.4rem;
}

.btn--lg {
  --size: 5rem;
  --padding: 3rem;
  --font-size: 1.4rem;
  @screen lg {
    --size: 7.4rem;
    --padding: 4rem;
    --font-size: 1.6rem;
  }
}

.btn--md {
  --size: 5rem;
  @screen lg {
    --size: 6.4rem;
  }

  --padding: 2.6rem;
}

.btn--sm {
  --padding: 2.2rem;
  --size: 4.2rem;
  @screen lg {
    --size: 5.2rem;
  }
}

.btn--tiny {
  --size: 3.5rem;
  --padding: 2.2rem;
}

.btn--primary {
  --bg: theme('colors.primary.DEFAULT');
  --color: #fff;
  background-image: var(--gradient-primary);

  &::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--gradient-primary);
  }

  &:not([disabled]):not(.disabled):hover::before {
    opacity: 1;
  }

  &-outlined {
    --bg: transparent;
    --color: theme('colors.primary.DEFAULT');
    border: 2px solid theme('colors.primary.DEFAULT');

    &::before {
      background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--gradient-primary);
      @apply -inset-2;
    }
    // Use hover if device supports hover
    @media (hover: hover) {
      &:not([disabled]):not(.disabled):hover {
        --color: #fff;
        border-color: transparent;

        &::before {
          opacity: 1;
        }
      }
    }

    // Use active if device does not support hover
    @media (hover: none) {
      &:not([disabled]):not(.disabled):active {
        --color: #fff;
        border-color: transparent;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.btn--red {
  --bg: theme('colors.red.DEFAULT');
  --color: #fff;

  &-outlined {
    --bg: transparent;
    --color: theme('colors.red.DEFAULT');
    border: 2px solid theme('colors.red.DEFAULT');

    &::before {
      background-color: theme('colors.red.DEFAULT');
      background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
      @apply -inset-2;
    }

    &:not([disabled]):not(.disabled):hover {
      --color: #fff;
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}

.btn--text-gray {
  --bg: transparent;
  --color: theme('colors.gray.DEFAULT');

  &::before {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--gradient-primary);
  }

  // Use hover if device supports hover
  @media (hover: hover) {
    &:hover {
      --color: theme('colors.white');

      &::before {
        opacity: 1;
      }
    }
  }
  // Use active if device does not support hover
  @media (hover: none) {
    &:active {
      --color: theme('colors.white');

      &::before {
        opacity: 1;
      }
    }
  }
}

.btn--text-black {
  --bg: transparent;
  --color: theme('colors.black.DEFAULT');

  &::before {
    background-image: var(--gradient-primary);
  }

  &:hover {
    --color: theme('colors.white');

    &::before {
      opacity: 1;
    }
  }
}

.btn--text-black-red {
  --bg: transparent;
  --color: theme('colors.black.DEFAULT');

  &::before {
    @apply bg-red;
  }

  &:hover {
    --color: theme('colors.white');

    &::before {
      opacity: 1;
    }
  }
}

.btn--white {
  --bg: #fff;
  --color: theme('colors.black.DEFAULT');

  &::before {
    background-image: var(--gradient-primary);
  }

  &:hover {
    --color: theme('colors.white');

    &::before {
      opacity: 1;
    }
  }

  &-outlined {
    --bg: transparent;
    --color: #fff;
    border: 2px solid #fff;

    &::before {
      background: #fff;
      @apply -inset-2;
    }

    &:not([disabled]):not(.disabled):hover {
      --color: theme('colors.black.DEFAULT');
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}

.btn--black {
  --bg: #000;
  --color: theme('colors.white');

  &::before {
    background-color: theme('colors.black.lighter');
  }

  &:hover {
    --color: theme('colors.white');

    &::before {
      opacity: 1;
    }
  }

  &-outlined {
    --bg: transparent;
    --color: #fff;
    border: 2px solid #fff;

    &::before {
      background: #fff;
      @apply -inset-2;
    }

    &:not([disabled]):not(.disabled):hover {
      --color: theme('colors.black.DEFAULT');
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}

.btn--white-primary {
  --bg: #fff;
  --color: theme('colors.black.DEFAULT');
  border: 2px solid transparent;

  &::before {
    background: #fff;
  }

  &:hover {
    /* --color: theme('colors.white'); */
    &::before {
      opacity: 1;
    }
  }

  &:not([disabled]):not(.disabled):hover {
    background: transparent;
    --color: #fff;
    border: 2px solid #fff;
  }
}

.btn--light-primary {
  &::before {
    background: var(--gradient-light);
    opacity: 1;
  }

  // Use hover if device supports hover
  @media (hover: hover) {
    &:hover {
      &::before {
        background: var(--gradient-light-hover);
      }
    }
  }

  // Use active if device does not support hover
  @media (hover: none) {
    &:active {
      &::before {
        background: var(--gradient-light-hover);
      }
    }
  }

  &-text {
    /* Fallback styles for older browsers */
    color: var(--gradient-primary-fallback);

    /* Override for browsers that support gradient text */
    @supports ((-webkit-background-clip: text) and (background-image: linear-gradient(to right, red, blue))) or
      ((background-clip: text) and (background-image: linear-gradient(to right, red, blue))) {
      background-image: var(--gradient-primary);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }
}
