mark {
  @apply bg-primary/10;
  color: inherit;
}

.prose {
  :where(:is(.p, p) + :is(.p, p)) {
    margin-top: 0.5em;
  }
  a {
    /* background-clip: text;
      background-image: var(--gradient-primary); */
    /* color: transparent; */

    @apply text-primary underline decoration-primary decoration-1 underline-offset-4 transition-colors visited:opacity-50 hover:decoration-transparent;
  }
  &--white {
    a {
      background-clip: initial;
      background-image: none;
      color: white;
      font-weight: inherit;
      @apply decoration-white/75;
    }
  }
}
