:is(input, textarea)::placeholder {
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.control-wrapper {
  text-align: left;
  &__title {
    font-size: 1.6rem;
    margin-bottom: 0.7rem;
  }
}

.input-base {
  background: transparent;
  line-height: normal;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border-width: 0;
  box-shadow: none;
  appearance: none;
  transition: 0.15s;
  border-radius: 0;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &::placeholder {
    opacity: 1;
  }
}

.text-field,
.select-field {
  text-overflow: ellipsis;
  line-height: normal;
  background: transparent;
  display: block;
  width: 100%;
  box-shadow: none;
  appearance: none;
  transition: 0.15s;
  --border-radius: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: transparent;
  --border: var(--border-width) var(--border-style) var(--border-color);
  --bg: #fff;
  height: var(--size);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
  padding: 0 var(--padding);
  border: var(--border);
  background: var(--bg);
  @apply rounded-sm text-black outline outline-1 outline-transparent lg:rounded;
  text-align: left;
  --padding: 2.4rem;
  --font-size: 1.6rem;
  --size: 8rem;
  font-weight: bold;
  &:is(textarea) {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
    --size: auto;
  }
  &:hover {
    /* --border-color: theme('colors.black.DEFAULT'); */
  }
  &:focus {
    /* @apply outline outline-1  outline-primary; */
  }
  &::placeholder {
    opacity: 1;
    color: #8b8b8b;
  }
}

.text-field-wrapper {
  position: relative;
  display: block;
  &__title {
    position: absolute;
    top: var(--title-top, 2.7rem);
    left: 2.4rem;
    right: 2.4rem;
    @apply text-gray;
    transition: 0.15s;
    transform-origin: left center;
    z-index: 4;
    pointer-events: none;
  }
  &:is(:not(.text-field-wrapper--ignore-focus):focus-within, .has-value) &__title {
    transform: translateY(-1.8rem) scale(0.75);
  }
}

.text-field-wrapper.text-field-small {
  --title-top: 2rem;
}

*:is(.select-field, .text-field):where(.text-field-wrapper__title + *) {
  padding-top: 0.5rem;
}

.radio {
  appearance: none;
  box-shadow: none;
  border-radius: 50%;
  border: 2px solid #dcdedc;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: 1rem;
    height: 1rem;
    border-radius: inherit;
    background-image: var(--gradient-primary);
    transition: 0.15s;
    opacity: 0;
  }
  &:where(:checked, .checked)::before {
    opacity: 1;
  }
}

.text-field.text-field-small,
.select-field.text-field-small {
  --size: 6rem;
}
