@import 'tailwindcss/base';

@import 'fonts.scss';
@import 'buttons.scss';
@import 'switcher.scss';
@import 'link.scss';
@import 'modal.scss';
@import 'vars.scss';
@import 'container.scss';
@import 'typo.scss';
@import 'text-field.scss';
@import 'layout.scss';
@import 'loader.scss';

html {
  @apply font-primary;
  font-size: 62.5%;
  overscroll-behavior-y: none;
}

/* 
This CSS block ensures that numbers are aligned vertically to avoid default Raleway font jitter 
Borrowed here – https://stackoverflow.com/a/17222187/12925471
*/
* {
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}

html:not([data-headlessui-focus-visible]) *:focus {
  // Workaround for a problem in headless ui where it activate focus-visible for no reason
  outline: none !important;
}

body {
  @apply text-black sm:text-base;
  font-size: 1.5rem;
  background-color: #fbfafc;
}

.greyed-out {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  pointer-events: all;
}

.external-browser-banner {
  position: relative;
  z-index: 9999;
}

.no-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

[hidden] {
  display: none !important;
}

@tailwind components;
@tailwind utilities;

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

// clear default browser styles for input type="search"
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
