.Skeleton {
  --skeleton-color: #dcdedc;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
    background: linear-gradient(to right, transparent 0%, var(--skeleton-color) 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}
