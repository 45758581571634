.sidebar {
  width: 100%;
  background-color: var(--sidebar-bg);
  flex-shrink: 0;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: 0.2s;
  z-index: 30;
  @screen lg {
    display: grid;
    transform: none;
    position: sticky;
    width: auto;
    align-self: flex-start;
    grid-template-columns: 8rem 32rem;
  }
  @screen xl {
    grid-template-columns: 10.5rem 36.3rem;
  }
  &--short {
    @screen lg {
      grid-template-columns: 8rem;
    }
    @screen xl {
      grid-template-columns: 10.5rem;
    }
  }
}
.inner-main-wrapper {
  min-height: 100%;
  display: flex;
  @screen lg {
    overflow: hidden;
    height: 100%;
    display: flex;
  }
}
.inner-main-wrapper--open-sidebar .sidebar {
  transform: translateX(0);
}

#root {
  @screen lg {
    // With this style swipe in react-slick does not work on iOS, so we only apply it for desktop
    // Although when I removed it completely everything seems to work
    display: contents;
  }
}
