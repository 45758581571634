.link {
  @apply underline underline-offset-2 transition-colors;
  text-underline-position: from-font;
  &--red {
    @apply text-red decoration-red/50 hover:decoration-transparent;
  }
  &--white {
    @apply text-white decoration-white/50 hover:decoration-transparent;
  }
  &--primary-gradient {
    /* Fallback styles for older browsers */
    color: var(--gradient-primary-fallback);

    /* Override for browsers that support gradient text */
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      background-image: var(--gradient-primary);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
    @apply decoration-primary/50 hover:decoration-transparent;
  }

  &--bold {
    font-weight: bold;
  }
}
