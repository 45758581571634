:root {
  --container-base-width: 124rem;
  --container-width: calc(var(--container-base-width) + var(--container-padding) * 2);
  --container-padding: 2rem;
  @screen lg {
    --container-padding: 4.8rem;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: var(--container-width);
}
